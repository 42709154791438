@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Roboto:wght@300&family=Source+Sans+Pro:ital,wght@0,600;0,700;1,400&display=swap');

html,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app{
  display: flex;
  position: relative;
  background-color: "red";
}

.css-qvlsrm {
  height: 0vh !important;  /* Add !important to ensure it overrides the default styles */
}

.css-aamkna {
  height: 0vh !important;
}

.css-19midj6 {
  padding: 0vw !important;
}

::-webkit-scrollbar-track{
  background: #ffffff;
}


::-webkit-scrollbar-thumb{
  background: #b6b4b4;
  
} 

::-webkit-scrollbar-track:hover{
  background: #ffffff;
} 

.content {
  display: flex;
  flex-direction: column;
  height: 100vh; 
}

.layout {
  display: flex; 
  flex-grow: 1; 
}

.route-container {
  flex-grow: 1; 
  height: 100vh;;
}


